import * as React from 'react'
import Seo from '../components/seo'
// import Video from '../components/video'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'

const Lista = () => {
  return (
    <>
      <Seo title='Lista' />
      <Layout>
        <article className='lista'>
          <div className='kandidat'>
            <StaticImage
              src='../images/Marinko_Zic.jpg'
              alt='Marinko Žic'
              layout='constrained'
              placeholder='blur'
              quality='100'
              objectFit='contain'
              objectPosition='0 0'
              aspectRatio='1'
              imgClassName='kandidat-slika'
            />
            <div className='bio'>
              <h2>Marinko Žic</h2>
              <h3>
                kandidat za načelnika Općine Punat i nositelj liste koalicije
                SDP-PGS-HSU
              </h3>
              <p>
                <a href='/nacelnik'>Životopis na stranici načelnika...</a>
              </p>
            </div>
          </div>
          <div className='kandidat'>
            <StaticImage
              src='../images/Goran_Grzancic.jpg'
              alt='Goran Gržančić'
              layout='constrained'
              placeholder='blur'
              quality='100'
              objectFit='contain'
              objectPosition='0 0'
              aspectRatio='1'
              imgClassName='kandidat-slika'
            />
            <div className='bio'>
              <h2>Goran Gržančić</h2>
              <p>
                Rođen sam&nbsp; 14. veljače 1956., po zanimanju sam ginekolog u
                vlastitoj ginekolo&scaron;koj ordinaciji u Krku.
              </p>
              <p>
                Predsjednik sam ogranka PGS-a Punat, a zadnja 2 mandata
                predsjednik Općinskog vijeća Općine Punat.
              </p>
              <p>
                Aktivno sudjelujem u radu udruge Kumpanija &Scaron;torije Punat
                i Mje&scaron;ovitog pjevačkog zbora KUD Ive Jurjević
                Omi&scaron;alj.
              </p>
              <p>
                Ako bih svoje hobije i slobodno vrijeme poredao po važnosti i
                angažmanu, onda bi to bilo: politika, maslinarstvo, skijanje,
                putovanja, pa opet politika.
              </p>
              <p>
                Sretno sam oženjen Sandrom, profesoricom u Osnovnoj
                &scaron;koli, imam dva sina koji kreću mojim stopama, Sandra,
                specijalizanta ginekologije i Maria koji zavr&scaron;ava studij
                dentalne medicine.
              </p>
            </div>
          </div>
          <div className='kandidat'>
            <StaticImage
              src='../images/Maja_Polonijo.jpg'
              alt='Maja Polonijo'
              layout='constrained'
              placeholder='blur'
              quality='100'
              objectFit='contain'
              objectPosition='0 0'
              aspectRatio='1'
              imgClassName='kandidat-slika'
            />
            <div className='bio'>
              <h2>Maja Polonijo</h2>
              <p>
                Rođena sam 1978. godine u Rijeci. Udata sam i majka dvije kćeri.
                Diplomirala&nbsp;&nbsp;sam na Filozofskom fakultetu u Rijeci, u
                zvanju profesora hrvatskog jezika i književnosti. Od 2004.
                godine radim u O&Scaron; &uml;Fran Krsto Frankopan&uml; Krk, a
                nakon razdvajanja &scaron;kola, radim u O&Scaron; Omi&scaron;alj
                P&Scaron; Dobrinj. Radim na mno&scaron;tvu projekata i aktivno
                sam uključena u rad &scaron;kole.
              </p>
              <p>
                Članica sam &Scaron;kolskog odbora S&Scaron; &uml;Hrvatski kralj
                Zvonimir&uml;u Krku te predsjednica Odbora za dru&scaron;tvene
                djelatnosti Općine Punat.
              </p>
              <p>
                Cijelo djetinjstvo provela sam u Puntu aktivno se baveći
                folklorom i pjevanjem u zboru. Preko 20 godina pomažem u radu
                Narodne knjižnice i čitaonice u Puntu te svoje slobodno vrijeme
                često provodim u knjižnici.
              </p>
              <p>
                U slobodno vrijeme, osim volontiranja u knjižnici, volim čitati
                knjige i gledati filmove. Moj smjer interesa je razvoj
                obrazovanja, poticanje čitalačke i medijske pismenosti te razvoj
                sportskih aktivnosti u lokalnoj zajednici.
              </p>
            </div>
          </div>
          <div className='kandidat'>
            <StaticImage
              src='../images/Ana_Lelekovic.jpg'
              alt='Ana Leleković'
              layout='constrained'
              placeholder='blur'
              quality='100'
              objectFit='contain'
              objectPosition='0 0'
              aspectRatio='1'
              imgClassName='kandidat-slika'
            />
            <div className='bio'>
              <h2>Ana Leleković</h2>
              <p>
                Zovem se Ana Leleković, rođena sam 07.04.1979. godine.
                Zavr&scaron;ila sam&nbsp; srednju &scaron;kolu Hrvatski kralj
                Zvonimir Krk i stekla zvanje hotelijersko turistički tehničar.
              </p>
              <p>Radim kao hotelska domaćica u Vinotelu Gospoja Vrbnik.</p>
              <p>
                Nisam članica niti jedne stranke, a u slobodno vrijeme
                volontiram u župnom Caritasu u Puntu.
              </p>
            </div>
          </div>
          <div className='kandidat'>
            <StaticImage
              src='../images/Hrvoje_Mrakovcic.jpg'
              alt='Hrvoje Mrakovčić'
              layout='constrained'
              placeholder='blur'
              quality='100'
              objectFit='contain'
              objectPosition='0 0'
              aspectRatio='1'
              imgClassName='kandidat-slika'
            />
            <div className='bio'>
              <h2>Hrvoje Mrakovčić</h2>
              <p>
                Zovem se Hrvoje Mrakovčić, rođen sam 26.8.1973. u Rijeci,
                odrastao i živim u Puntu. Po zavr&scaron;etku Srednje
                &scaron;kole u Krku, služio sam HV, a s 19 godina sudjelovao u
                Domovinskom ratu te imam status hrvatskog branitelja. Od 1997.
                godine sam obrtnik. Veliki sam zaljubljenik podmorja pa se tako
                iz hobija razvio obiteljski posao. Rekreativno ronjenje postalo
                je instruktorsko zanimanje, a Podvodni park Punat kao prvi
                podvodni park u RH dobio je mnoga priznanja na nivou županije i
                države na koji samo jako ponosni. U mladosti sam se aktivno
                bavio rukometom u puntarskom klubu, te rekreativno odbojkom,
                ko&scaron;arkom i nogometom. Član sam DVD Podružnice Punat,
                predsjednik&nbsp; Kluba podvodnih aktivnosti
                &bdquo;PUNAT&ldquo;, član Nadzornog odbora &bdquo;HRS&ldquo;-a.
              </p>
              <p>
                Aktivan sam u političkom životu na&scaron;e lokalne zajednice te
                općinski vijećnik u tri mandata.
              </p>
            </div>
          </div>
          <div className='kandidat'>
            <StaticImage
              src='../images/Kristijan_Zic.jpg'
              alt='Kristijan Žic'
              layout='constrained'
              placeholder='blur'
              quality='100'
              objectFit='contain'
              objectPosition='0 0'
              aspectRatio='1'
              imgClassName='kandidat-slika'
            />
            <div className='bio'>
              <h2>Kristijan Žic</h2>
              <p>
                Moje ime je Kristijan Žic. Rođen sam 16.10.1995. u Rijeci, i od
                tada živim u Puntu. Zavr&scaron;io sam opću gimnaziju u Krku.
                Studiram na upravnom studiju Pravnog fakulteta u Rijeci i
                trenutno uređujem svoj zavr&scaron;ni rad na temu Dodjela
                koncesija u lukama posebne namjene u Republici Hrvatskoj koji bi
                trebao obraniti u sljedećim mjesecima. U sklopu zavr&scaron;nog
                rada odradio sam stručnu praksu u Općini Punat gdje sam se
                upoznao sa svim djelatnostima i poslovima općine, a na svoj rad
                sam dobio pohvale. U sklopu fakulteta sudjelovao sam na
                simuliranom parlamentu u Hrvatskom saboru.
              </p>
              <p>
                Moje radno iskustvo počinje u Općini Punat u sklopu "bijele
                patrole", a zatim u KD Črnika na kontroli parkinga, jo&scaron; i
                konobarim na ljetnim fe&scaron;tama.
              </p>
              <p>
                Član sam SDP-a i trenutno obna&scaron;am dužnost vijećnika u
                Općini Punat. Član sam odbora za dru&scaron;tvene djelatnosti i
                član povjerenstva za zakup poljoprivrednog zemlji&scaron;ta u
                vlasni&scaron;tvu RH na području Općine Punat.
              </p>
              <p>
                Aktivni sam član i jedan od osnivača Udruge mladih Fenix,&nbsp;
                aktivni član KUD-a Punat, Kumpanije &Scaron;torije i Puntarskih
                užanci.&nbsp; S članovima Udruge mladih Fenix volontirao sam u
                projektu 72 sata bez kompromisa u organizaciji biskupijskog
                Caritasa.
              </p>
              <p>Imam troje braće i stric sam troje nećaka.</p>
              <p>
                U slobodno vrijeme družim se s prijateljima, volim &scaron;etnje
                i planinarenja, te idem na rekreaciju u novu dvoranu osnovne
                &scaron;kole u Puntu.
              </p>
            </div>
          </div>
          <div className='kandidat'>
            <StaticImage
              src='../images/Irena_Orlic.jpg'
              alt='Irena Žic Orlić'
              layout='constrained'
              placeholder='blur'
              quality='100'
              objectFit='contain'
              objectPosition='0 0'
              aspectRatio='1'
              imgClassName='kandidat-slika'
            />
            <div className='bio'>
              <h2>Irena Žic Orlić</h2>
              <p>
                Ja sam Irena Žic Orlić, rođena sam 19.12.1966. godine u Zagrebu.
                Udana sam, majka dvoje djece, s prebivali&scaron;tem u Puntu od
                1988. godine.
              </p>
              <p>
                Na Filozofskom fakultetu u Zagrebu diplomirala sam Pedagogijske
                znanosti, a po zanimanju sam stručna prvostupnica
                pred&scaron;kolskog odgoja. Od 1988. godine zaposlena sam u
                otočkom Dječjem vrtiću &bdquo;Katarina Frankopan&ldquo;, a od
                2003. godine do danas obna&scaron;am funkciju ravnateljice
                vrtića.
              </p>
              <p>
                Istaknula bih osobnu poslovnu kvalitetnu suradnju sa svim
                jedinicama lokalne samouprave na nivou otoka Krka &scaron;to
                dokazuju objektivni pokazatelji stručnog i materijalnog napretka
                ustanove. Naime, posljednjih 10-ak godina na otoku je izgrađeno
                i adaptirano sedam vrtića, a Dječjem vrtiću &bdquo;Katarina
                Frankopan&ldquo; 2019. godine pripojio se i vrtić u Puntu pod
                tada&scaron;njim nazivom Lastavica.
              </p>
              <p>
                Kao članica Općinskog vijeća Punta aktivno sudjelujem u životu
                lokalne zajednice brinući za stvaranje uvjeta za bolji i
                kvalitetniji život u na&scaron;oj sredini vodeći računa o
                dobrobiti i prosperitetu svih stanovnika mje&scaron;tana Stare
                Ba&scaron;ke i Punta.
              </p>
              <p>
                Članica sam tima za prevenciju i borbu protiv nasilja i drugih
                ugroza na lokalnoj razini (otok Krk) koji je uspostavljen radi
                prevencije potencijalnih opasnosti i ugroza s neželjenim
                posljedicama po život, sigurnost i zdravlje građana u okviru
                mojih profesionalnih kompetencija te se nalazimo na mjesečnoj
                bazi.
              </p>
              <p>
                Dugi niz godina sam član povjerenstva za odabir sporta&scaron;a
                godine otoka Krka.
              </p>
              <p>
                Osobno bih se opisala kao komunikativnu i dru&scaron;tvenu osobu
                koja cijeni timski rad s potpunim uvažavanjem međusobne
                različitosti. Vrijednosti koje me vode kroz život su
                po&scaron;tenje, marljivost, briga za druge i za opće dobro.
              </p>
            </div>
          </div>
          <div className='kandidat'>
            <StaticImage
              src='../images/Dragica_Brajac.jpg'
              alt='Dragica Brajač'
              layout='constrained'
              placeholder='blur'
              quality='100'
              objectFit='contain'
              objectPosition='0 0'
              aspectRatio='1'
              imgClassName='kandidat-slika'
            />
            <div className='bio'>
              <h2>Dragica Brajač</h2>
              <p>Rođena sam 20.4.1949., po zanimanju sam krojačica.</p>
              <p>
                Trideset sam godina radila u struci u Riječkoj industriji odjeće
                (RIO), sada sam umirovljenica.
              </p>
              <p>
                Nisam članica niti jedne stranke, a politički sam lijevo
                orijentirana.
              </p>
              <p>
                Kroz godine sam bila aktivna kroz mnoge udruge i klubove, pa sam
                tako 4 godine bila predsjednica &Scaron;RD Arbun i članica
                odbora Udruge umirovljenika u četverogodi&scaron;njem mandatu.
              </p>
              <p>
                Uz navedene udruge, članica sam i udruge Puntarske užance i jako
                volim ma&scaron;kare.
              </p>

              <p>
                Kao aktivna članica Boćarskog kluba natjecala sam se i osvajala
                pehare sve dok me ozljeda ramena nije onemogućila.
              </p>
              <p>Rado volontiram i pomažem u humanitarnom radu.</p>
              <p>
                Udovica sam koja jako voli putovanja i sebe opisujem kao
                svjetskog putnika.
              </p>
            </div>
          </div>
          <div className='kandidat'>
            <StaticImage
              src='../images/Zeljan_Zic.jpg'
              alt='Željan Žic'
              layout='constrained'
              placeholder='blur'
              quality='100'
              objectFit='contain'
              objectPosition='0 0'
              aspectRatio='1'
              imgClassName='kandidat-slika'
            />
            <div className='bio'>
              <h2>Željan Žic</h2>
              <p>
                Rođen sam 1960. godine živim u Puntu, a radim u Malinskoj kao
                liječnik obiteljske medicine. Bio sam općinski vijećnik u 2
                mandata, 1993.-2001. godine. U slobodno vrijeme uživam u
                maslinarstvu.
              </p>
            </div>
          </div>
        </article>
      </Layout>
    </>
  )
}

export default Lista
